import React, { Component } from "react";
import Header from "../../../components/header/Headers.js";
import TopButton from "../../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./sesame.css";
import Footer from "../../../components/footer/Footer.js";

const words = "SESAME SEEDS".split(" ");

class Sesame extends Component {
  render() {
    const theme = this.props.theme;
    const paragraphs = [
      "✔️ <strong>Appearance:</strong> Sesame seeds are small, flat, and oval-shaped seeds with a shiny outer coat. They come in various colors, including white, black, and brown, depending on the variety.",
      "✔️ <strong>Flavor and Aroma:</strong> Sesame seeds have a rich, nutty flavor and a subtle, aromatic fragrance. When toasted, sesame seeds develop a deeper, more intense flavor profile, enhancing the overall taste of dishes.",
      "✔️ <strong>Culinary Uses:</strong> Sesame seeds are widely used in cooking and baking across different cuisines worldwide. They are often sprinkled on top of bread, buns, and pastries for added texture and flavor.",
      "✔️ <strong>Health Benefits:</strong> Sesame seeds offer various health benefits, including supporting bone health due to their calcium content, promoting heart health by reducing cholesterol levels, and aiding digestion owing to their fiber content."
    ];
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: theme.text ,  fontFamily: "Times New Roman"}}>
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div" style={{ marginTop: "2%" }}>
              <div className="contact-heading-img-div">
                <img alt="" src={require("../../../assests/images/d13.png")} />
              </div>
              <div className="contact-heading-text-div">
                <p className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "16px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }}>
          
Sesame seeds are tiny, flat seeds with a delicate nutty flavor and a crunchy texture. They come in different colors, including white, black, and brown varieties. Sesame seeds are rich in healthy fats, protein, fiber, and various essential nutrients such as calcium, iron, magnesium, and zinc. They are commonly used in cooking and baking, adding flavor, texture, and nutritional value to dishes like salads, stir-fries, baked goods, and desserts. 
                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "15px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Sesame;
