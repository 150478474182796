import React from "react";
import "./Products.css";
import ProjectCard from "../projects/ProjectCard";

import { projects } from "../../portfolio";
import { Fade } from "react-reveal";
const words = "Our Products".split(" ");

export default function Products(props) {
  const theme = props.theme;
  return (
    <div className="main" id="products">
      <div className="skills-header-div">
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            {words.map((word, index) => (
              <span
                key={index}
                style={{
                  borderBottom: index === 1 ? "4px solid #fca326" : "inherit", fontFamily: "Times New Roman"
                }}
              >
                {word}{" "}
              </span>
            ))}
          </h1>
        </Fade>
        <div className="certs-body-div">
          {projects.projects.map((cert) => {
            return <ProjectCard certificate={cert} theme={theme} />;
          })}
        </div>
      </div>
    </div>
  );
}
