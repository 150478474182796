import React, { Component } from "react";
import "./projectCard.css";
import { Fade } from "react-reveal";
import {Link} from "react-router-dom"
class ProjectCard extends Component {
  render() {
    const certificate = this.props.certificate;
    const theme = this.props.theme;
    return (
      <Fade bottom duration={2000} distance="20px">
        {/* <Link to="/contact" style={{ textDecoration: "none", color: "inherit" }}>         */}
        <div className="cert-card">
        <Link to={`/products/${certificate.detail}`} style={{ textDecoration: "none", color: "inherit" }}>     
         
          <div className="content">
            <img
              className="logo_img"
              src={require(`../../assests/images/${certificate.logo_path}`)}
              alt={certificate.alt_name}
            />
            {/* </div> */}
            <div className="content-details fadeIn-top">
              <h3 className="content-title" style={{ color: theme.body }}>
                {/* <img
                  className="logo_img"
                  src={require(`../../assests/images/${certificate.hover}`)}
                  alt={certificate.alt_name}
                /> */}
              </h3>
            </div>
           
          </div>
          <div className="cert-body">
            <h2 className="cert-body-title" style={{ color: theme.text , fontFamily: "Times New Roman" }}>
              {certificate.title}
            </h2>
        
            <h4
              className="cert-body-subtitle"
              style={{ color: "black", textDecoration: "underline" }}
            >
             Click Here
            </h4>
          </div>
          </Link>
        </div>
        {/* </Link> */}
      </Fade>
    );
  }
}
export default ProjectCard;
