import React, { Component } from "react";
import Header from "../../../components/header/Headers.js";
import TopButton from "../../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./kalonji.css";
import Footer from "../../../components/footer/Footer.js";

const words = "BLACK SEEDS".split(" ");

class Kalonji extends Component {
  render() {
    const theme = this.props.theme;
    const paragraphs = [
      "✔️ <strong>Appearance:</strong>  Small, black, angular seeds with a matte finish",
      "✔️ <strong>Flavor and Aroma:</strong> Kalonji has a slightly bitter taste with a nutty, peppery undertone. The seeds emit a pungent aroma when crushed or ground.",
      "✔️ <strong>Culinary Uses:</strong> Widely used in Indian and Middle Eastern cuisines. Commonly added to flatbreads, pickles, curries, and spice blends. It's also used as a topping for various bread and pastry items.",
    //   "✔️ <strong>Medical Uses:</strong>  Kalonji has been traditionally used in herbal medicine for its potential anti-inflammatory, antioxidant, and immune-boosting properties. It is believed to have various health benefits.",
      "✔️ <strong>Essential Oil: </strong>Kalonji seeds can be used to extract essential oil, which is utilized in aromatherapy and natural health products."
    ];
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: theme.text ,  fontFamily: "Times New Roman" }}>
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div" style={{ marginTop: "2%" }}>
              <div className="contact-heading-img-div">
                <img alt="" src={require("../../../assests/images/d4.png")} />
              </div>
              <div className="contact-heading-text-div">
                <p className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "16px", textAlign: "justify", marginLeft: "5px",  fontFamily: "Dollie Script"  }}>
              
Kalonji, also known as black seed or Nigella sativa, refers to the small, black seeds obtained from the flowering plant of the Ranunculaceae family. These seeds have a distinctive appearance and are valued for their culinary and potential health benefits. Here's some information about Kalonji:
                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "15px", textAlign: "justify", marginLeft: "5px",  fontFamily: "Dollie Script"  }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Kalonji;
