import React, { Component } from "react";
import Header from "../../../components/header/Headers.js";
import TopButton from "../../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./watermelon.css";
import Footer from "../../../components/footer/Footer.js";

const words = "WATERMELON SEEDS".split(" ");

class Watermelon extends Component {
  render() {
    const theme = this.props.theme;
    const paragraphs = [
      "✔️ <strong>Nutrient Content:</strong> Watermelon seeds are rich in essential nutrients, including protein, healthy fats, vitamins, and minerals. ",
      "✔️ <strong>Protein Source:</strong>  Watermelon seeds are a good source of plant-based protein, making them a suitable option for vegetarians and vegans looking to increase their protein intake. ",
      "✔️ <strong>Healthy Fats:  </strong> Watermelon seeds contain healthy fats, including monounsaturated and polyunsaturated fats, which are beneficial for heart health. ",
    ];
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: theme.text ,  fontFamily: "Times New Roman"}}>
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div" style={{ marginTop: "2%" }}>
              <div className="contact-heading-img-div">
                <img alt="" src={require("../../../assests/images/d10.png")} />
              </div>
              <div className="contact-heading-text-div">
                <p className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "16px", textAlign: "justify", marginLeft: "5px",  fontFamily: "Dollie Script"  }}>
                
Watermelon seeds are the small, oval-shaped seeds found within the flesh of watermelon (Citrullus lanatus), a popular and refreshing fruit enjoyed worldwide during the summer months. While often discarded, watermelon seeds are edible and offer several potential health benefits. Here's more information about watermelon seeds:
                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "15px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Watermelon;
