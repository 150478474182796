import React, { Component } from "react";
import Header from "../../../components/header/Headers.js";
import TopButton from "../../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./methidana.css";
import Footer from "../../../components/footer/Footer.js";

const words = "FENUGREEK SEEDS".split(" ");

class Cumin extends Component {
  render() {
    const theme = this.props.theme;
    const paragraphs = [
      "✔️ <strong>Appearance:</strong>  Small, angular, yellowish-brown seeds",
      "✔️ <strong>Flavor and Aroma:</strong>  Bitter taste with a slightly sweet undertone; strong, aromatic fragrance.",
      "✔️ <strong>Culinary Uses:</strong> Widely used in Indian, Middle Eastern, and North African cuisines. Commonly added to curries, pickles, spice blends, and bread.",
      "✔️ <strong>Digestive Aid:</strong> Methi Dana is known for its digestive properties and is often used to alleviate indigestion, bloating, and constipation. It may stimulate the production of digestive juices.",
      "✔️ <strong>Medicinal Uses:</strong>  In traditional medicine, fenugreek seeds are believed to have various health benefits, including potential anti-inflammatory and antidiabetic properties."
    ];
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: theme.text ,  fontFamily: "Times New Roman"}}>
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div" style={{ marginTop: "2%" }}>
              <div className="contact-heading-img-div">
                <img alt="" src={require("../../../assests/images/d3.png")} />
              </div>
              <div className="contact-heading-text-div">
                <p className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "16px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }}>
                Methi Dana, also known as fenugreek seeds, is a spice derived from the seeds of the Trigonella foenum-graecum plant. These small, golden-brown seeds have a bitter taste and a strong aroma. Here's some information about Methi Dana:
                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "15px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Cumin;
