import React, { Component } from "react";
import Header from "../../../components/header/Headers.js";
import TopButton from "../../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./herbs.css";
import Footer from "../../../components/footer/Footer.js";

const words = "NATURAL HERBS".split(" ");

class Herbs extends Component {
  render() {
    const theme = this.props.theme;
    const paragraphs = [
      "✔️ <strong>Culinary Uses:</strong> Many herbs are prized for their ability to enhance the flavor and aroma of food. Common culinary herbs include basil, parsley, cilantro, thyme, rosemary, mint, and oregano. They are used fresh or dried in cooking to season dishes, add freshness, and create depth of flavor.",
      "✔️ <strong>Health Benefits:</strong> Many herbs contain beneficial compounds such as antioxidants, vitamins, minerals, and essential oils that can support overall health and well-being. ", 
      "✔️ <strong>Medical Uses:</strong> Numerous herbs have been used in traditional medicine systems worldwide for their therapeutic properties. ",
    ];
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: theme.text , fontFamily: "Times New Roman" }}>
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div" style={{ marginTop: "2%" }}>
              <div className="contact-heading-img-div">
                <img alt="" src={require("../../../assests/images/d9.png")} />
              </div>
              <div className="contact-heading-text-div">
                <p className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "16px", textAlign: "justify", marginLeft: "5px",  fontFamily: "Dollie Script"  }}>
                Natural herbs refer to plants or plant parts that are used for culinary, medicinal, aromatic, or ornamental purposes due to their inherent properties. These herbs are typically derived from the leaves, stems, flowers, roots, seeds, or bark of various plants and have been used by humans for centuries for their diverse benefits. Here are some key points about natural herbs:
                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "15px", textAlign: "justify", marginLeft: "5px",  fontFamily: "Dollie Script"  }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Herbs;
