import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Contact from "../pages/contact/contact";
import Cumin from "../pages/details/cumin/cumins";
import Ajwain from "../pages/details/ajwain/ajwain";
import MethiDana from "../pages/details/methidana/methidana";
import Kalonji from "../pages/details/kalonji/kalonji";
import Error404 from "../pages/errors/error404/Error";
import Khaskhas from "../pages/details/khaskhas/khaskhas";
import Tulsi from "../pages/details/tulsi/tulsi";
import Dhaniya from "../pages/details/dhaniya/dhaniya";
import Herbs from "../pages/details/herbs/herbs";
import Watermelon from "../pages/details/watermelon/watermelon";
import Chia from "../pages/details/chia/chia";
import Alsi from "../pages/details/alsi/alsi";
import Sesame from "../pages/details/sesame/sesame";
export default class Main extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => <Home {...props} theme={this.props.theme} />}
          />
          <Route
            path="/home"
            render={(props) => <Home {...props} theme={this.props.theme} />}
          />

          <Route
            path="/contact"
            render={(props) => <Contact {...props} theme={this.props.theme} />}
          />
          <Route
            path="/products/cumin"
            render={(props) => <Cumin {...props} theme={this.props.theme} />}
          />
          <Route
            path="/products/ajwain"
            render={(props) => <Ajwain {...props} theme={this.props.theme} />}
          />
          <Route
            path="/products/methidana"
            render={(props) => (
              <MethiDana {...props} theme={this.props.theme} />
            )}
          />
          <Route
            path="/products/kalonji"
            render={(props) => <Kalonji {...props} theme={this.props.theme} />}
          />
          <Route
            path="/products/khaskhas"
            render={(props) => <Khaskhas {...props} theme={this.props.theme} />}
          />
          <Route
            path="/products/tulsi"
            render={(props) => <Tulsi {...props} theme={this.props.theme} />}
          />
          <Route
            path="/products/dhaniya"
            render={(props) => <Dhaniya {...props} theme={this.props.theme} />}
          />
          <Route
            path="/products/herbs"
            render={(props) => <Herbs {...props} theme={this.props.theme} />}
          />
          <Route
            path="/products/watermelon"
            render={(props) => (
              <Watermelon {...props} theme={this.props.theme} />
            )}
          />
          <Route
          path="/products/chia"
          render={(props) => (
            <Chia {...props} theme={this.props.theme} />
          )}
        />
         <Route
          path="/products/alsi"
          render={(props) => (
            <Alsi {...props} theme={this.props.theme} />
          )}
        />
        <Route
          path="/products/sesame"
          render={(props) => (
            <Sesame {...props} theme={this.props.theme} />
          )}
        />
          <Route
            path="*"
            render={(props) => <Error404 {...props} theme={this.props.theme} />}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}
