import React, { Component } from "react";
import TopButton from "../../components/topButton/TopButton.js";
import "./contact.css";
import { Fade } from "react-reveal";
import SocialMedia from "../../components/socialMedia/SocialMedia.js";
const words = "Contact Us".split(" ");
class Experience extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="experience-main" id="contact">
        <div className="basic-experience">
          <Fade bottom duration={2000} distance="40px">
            <div className="experience-heading-div">
              <div className="experience-heading-img-div">
                <img
                  alt="Logo"
                  src={require("../../assests/images/logo.jpeg")}
                ></img>
              </div>
              <div className="experience-heading-text-div">
                 <h1 className="skills-header" style={{ color: theme.text }}>
                  {words.map((word, index) => (
                  <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" , fontFamily: "Times New Roman"}}>
                   {word}{' '}
                  </span>
                  ))}
          </h1>   
                <SocialMedia theme={theme} />
                <h3>Call us - 8178210709</h3>
                <h3>Email us- info@ksjoverses.com</h3>
                <h3>Location- Indore & Neemuch</h3>
              </div>
            </div>
          </Fade>
        </div>
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Experience;
