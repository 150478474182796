import React from "react";
import "./Greeting.css";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import TypingAnimation from "../../components/TypingAnimation";
import LoopingVideo from "../../components/greetVideo/video";

export default function Greeting(props) {
  const theme = props.theme;

  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="home">
        <div className="greeting-main">
          <div className="greeting-image-div">
            <img
              alt=""
              src={require("../../assests/images/greet.png")}
              className="background-image"
            />
          </div>
          <div className="greeting-text-div">
            <div>
              <h1 style={{ color: "white" , fontFamily: "Times New Roman" }}>
                {greeting.title}
              </h1>
              <TypingAnimation />
              <p
                className="greeting-text-p subTitle"
                style={{ color: "white" , fontFamily: "Dollie Script"  }}
              >
                {greeting.subTitle}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
