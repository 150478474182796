import React, { Component } from "react";
import Header from "../../../components/header/Headers.js";
import TopButton from "../../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./dhaniya.css";
import Footer from "../../../components/footer/Footer.js";

const words = "CORIANDER SEEDS".split(" ");

class Dhaniya extends Component {
  render() {
    const theme = this.props.theme;
    const paragraphs = [
      "✔️ <strong>Appearance:</strong> Coriander seeds are small, round, and tan-colored with ridges on their surface. They have a characteristic fragrance and flavor.",
      "✔️ <strong>Flavor and Aroma:</strong> Coriander seeds have a warm, citrusy, and slightly sweet flavor with hints of earthiness and spice.",
      "✔️ <strong>Culinary Uses:</strong> Coriander seeds are a versatile spice used in both whole and ground forms.",
      "✔️ <strong>Health Benefits:</strong> Coriander seeds contain antioxidants, vitamins, minerals, and dietary fiber", 
      "✔️ <strong>Medical Uses:</strong>  In traditional medicine systems such as Ayurveda",
    ];
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: theme.text ,  fontFamily: "Times New Roman"}}>
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div" style={{ marginTop: "2%" }}>
              <div className="contact-heading-img-div">
                <img alt="" src={require("../../../assests/images/d8.png")} />
              </div>
              <div className="contact-heading-text-div">
                <p className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "17px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script"   }}>
                Coriander seeds are the dried seeds of the coriander plant (Coriandrum sativum), which is a member of the parsley family. These seeds are widely used as a spice in various cuisines around the world and are known for their distinctive flavor and aroma. Here's some information about coriander seeds:                    
                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "15px", textAlign: "justify", marginLeft: "5px",   fontFamily: "Dollie Script" }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Dhaniya;
