import React, { Component } from "react";
import Header from "../../../components/header/Headers.js";
import TopButton from "../../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./alsi.css";
import Footer from "../../../components/footer/Footer.js";

const words = "ALSI SEEDS".split(" ");

class Cumin extends Component {
  render() {
    const theme = this.props.theme;
    const paragraphs = [
      "✔️ <strong>Appearance:</strong>They are usually brown or golden-yellow in color.",
      "✔️ <strong>Digestive Health:</strong> The high fiber content in alsi seeds supports digestive health by promoting regular bowel movements and preventing constipation. ",
      "✔️ <strong>Nutritional Benefits:</strong>  Alsi seeds are rich in omega-3 fatty acids, lignans, fiber, and protein. They also contain vitamins and minerals such as vitamin E, magnesium, and manganese.",
      "✔️ <strong>Storage:</strong> To maintain freshness, store alsi seeds in an airtight container in a cool, dry place, away from direct sunlight",
    ];
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: theme.text,  fontFamily: "Times New Roman" }}>
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div" style={{ marginTop: "2%" }}>
              <div className="contact-heading-img-div">
                <img alt="" src={require("../../../assests/images/d12.png")} />
              </div>
              <div className="contact-heading-text-div">
                <p className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "17px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }}>
                Alsi seeds offer numerous health benefits, including promoting heart health, aiding digestion, and regulating blood sugar levels. They can be easily incorporated into various dishes like smoothies, yogurt, salads, and baked goods, making them a versatile addition to a healthy diet. Remember to store them in a cool, dry place and consume ground or soaked flaxseeds for better nutrient absorption
                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "15px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Cumin;
