import React, { Component } from "react";
import Header from "../../../components/header/Headers.js";
import TopButton from "../../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./khaskhas.css";
import Footer from "../../../components/footer/Footer.js";

const words = "POPPY SEEDS".split(" ");

class Khaskhas extends Component {
  render() {
    const theme = this.props.theme;
    const paragraphs = [
      "✔️ <strong>Appearance:</strong>Small, round, and slate-blue or off-white in color.",
      "✔️ <strong>Flavor and Aroma:</strong> Mild, nutty flavor with a slightly sweet undertone. The seeds add a pleasant crunch to dishes.",
      "✔️ <strong>Culinary Uses:</strong> Widely used in various cuisines, particularly in Indian, Middle Eastern, and European dishes. Commonly used in baking, curries, gravies, and desserts.",
      "✔️ <strong>Medical Uses:</strong> In traditional medicine, poppy seeds have been used for their potential analgesic and calming properties.",
    ];
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: theme.text ,  fontFamily: "Times New Roman" }}>
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div" style={{ marginTop: "2%" }}>
              <div className="contact-heading-img-div">
                <img alt="" src={require("../../../assests/images/d5.png")} />
              </div>
              <div className="contact-heading-text-div">
                <p className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "16px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }}>
                    
Khas Khas, also known as poppy seeds, are tiny, kidney-shaped seeds obtained from the opium poppy plant (Papaver somniferum). These seeds are commonly used in culinary applications and are known for their mild, nutty flavor and crunchy texture. Here's some information about Khas Khas:              
                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "15px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script"  }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Khaskhas;
