import React, { Component } from "react";
import Header from "../../../components/header/Headers.js";
import TopButton from "../../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./ajwain.css";
import Footer from "../../../components/footer/Footer.js";

const words = "CAROM SEEDS".split(" ");

class Ajwain extends Component {
  render() {
    const theme = this.props.theme;
    const paragraphs = [
      "✔️ <strong>Appearance:</strong> Small, oval-shaped seeds with a brownish color.",
      "✔️ <strong>Flavor and Aroma:</strong> Carrom seeds have a strong, pungent aroma and a distinctive taste with hints of thyme and cumin.",
      "✔️ <strong>Culinary Uses:</strong> Widely used in Indian cuisine, especially in lentil dishes, bread, snacks, and pickles. Adds a unique flavor to various recipes.",
      "✔️ <strong>Digestive Aid:</strong> Known for its digestive properties, carrom seeds are often used to alleviate indigestion, bloating, and gas.",
      "✔️ <strong>Medicinal Uses:</strong> Used in traditional medicine for its potential anti-inflammatory, antispasmodic, and antibacterial properties."
    ];
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: theme.text , fontFamily: "Times New Roman"}}>
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div" style={{ marginTop: "2%" }}>
              <div className="contact-heading-img-div">
                <img alt="" src={require("../../../assests/images/d2.png")} />
              </div>
              <div className="contact-heading-text-div">
                <p className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "17px", textAlign: "justify", marginLeft: "5px" , fontFamily: "Dollie Script"  }}>
                  Carrom seeds, also known as ajwain or bishop's weed, are the tiny, oval-shaped seeds derived from the Trachyspermum ammi plant. These seeds are commonly used as a spice in Indian cuisine and traditional medicine. Here's a brief description of carrom seeds:
                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "15px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Ajwain;
