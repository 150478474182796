import React, { Component } from "react";
import Header from "../../../components/header/Headers.js";
import TopButton from "../../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./chia.css";
import Footer from "../../../components/footer/Footer.js";

const words = "CHIA SEEDS".split(" ");

class Cumin extends Component {
  render() {
    const theme = this.props.theme;
    const paragraphs = [
      "✔️ <strong>Appearance:</strong>Chia seeds are small, oval-shaped seeds with a smooth texture and a dark brown or black color.",
      "✔️ <strong>Flavor and Aroma:</strong>Chia seeds have a mild, nutty flavor and do not have a strong aroma. They blend well with various dishes without overpowering the taste.",
      "✔️ <strong>Culinary Uses:</strong> Chia seeds are versatile and can be added to smoothies, yogurt, oatmeal, salads, and baked goods like muffins and bread. ",
      "✔️ <strong>Nutritional Benefits:</strong> Chia seeds are packed with nutrients, including omega-3 fatty acids, fiber, protein, antioxidants, and various vitamins and minerals. ",
      "✔️ <strong>Hydration and Satiety:</strong> When soaked in water or other liquids, chia seeds form a gel-like consistency, which helps keep you hydrated and promotes a feeling of fullness, making them a popular choice for weight management and hydration during exercise."
    ];
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: theme.text,  fontFamily: "Times New Roman" }}>
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div" style={{ marginTop: "2%" }}>
              <div className="contact-heading-img-div">
                <img alt="" src={require("../../../assests/images/d11.png")} />
              </div>
              <div className="contact-heading-text-div">
                <p className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "17px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }}>
                Chia seeds, originating from Central America, are packed with essential nutrients. Rich in omega-3 fatty acids, fiber, and antioxidants, they support heart health, aid digestion, and promote weight management.
                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "15px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Cumin;
