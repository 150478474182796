import React, { Component } from "react";
import "./Header.css";
import { Fade } from "react-reveal";
import { Link } from "react-scroll";
import SeoHeader from "../seoHeader/SeoHeader";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomed: false
    };
  }

  toggleZoom = () => {
    this.setState({ zoomed: !this.state.zoomed });
  };

  render() {
    const { zoomed } = this.state;
    const theme = this.props.theme;

    return (
      <Fade top duration={1000} distance="20px">
        <div>
          <SeoHeader />
          <header className="header">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              activeClass="active"
              style={{ color: theme.text }}
            >
              <img
                onClick={this.toggleZoom}
                className={zoomed ? "zoomed" : ""}
                style={{ width: "65px", height: "55px" }}
                alt="Logo"
                src={require("../../assests/images/logo.jpeg")}
              />
            </Link>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn">
              <span className="navicon"></span>
            </label>
            <ul className="menu" style={{ backgroundColor: theme.body }}>
              <li>
                <Link
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  activeClass="active"
                  style={{ color: theme.text }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="products"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  activeClass="active"
                  style={{ color: theme.text }}
                >
                  Products
                </Link>
              </li>
              <li>
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  activeClass="active"
                  style={{ color: theme.text }}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  activeClass="active"
                  style={{ color: theme.text }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </header>
        </div>
        
      </Fade>
    );
  }
}

export default Header;
