import React, { Component } from "react";
import Header from "../../../components/header/Headers";
import TopButton from "../../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./tulsi.css";
import Footer from "../../../components/footer/Footer.js";

const words = "TULSI SEEDS".split(" ");

class Tulsi extends Component {
  render() {
    const theme = this.props.theme;
    const paragraphs = [
      "✔️ <strong>Appearance:</strong> : Small, round, and black when dry. When soaked in water, they swell and form a gelatinous outer layer with a black seed at the center.",
      "✔️ <strong>Flavor and Aroma:</strong>Tulsi seeds have a neutral flavor, allowing them to take on the taste of the liquids they are soaked in.",
      "✔️ <strong>Culinary Uses:</strong> Commonly used in beverages and desserts. ",
      "✔️ <strong>Weight Management:</strong>The high fiber content in Tulsi seeds may contribute to a feeling of fullness, which could support weight management.",
    ];
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: theme.text ,  fontFamily: "Times New Roman" }}>
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div" style={{ marginTop: "2%" }}>
              <div className="contact-heading-img-div">
                <img alt="" src={require("../../../assests/images/d7.png")} />
              </div>
              <div className="contact-heading-text-div">
                <p className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "16px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }}>
              

                Tulsi seeds, also known as basil seeds or Sabja seeds, come from the holy basil plant (Ocimum sanctum or Ocimum tenuiflorum). These small, black seeds are rich in nutrients and are popularly used in culinary and medicinal applications. Here's some information about Tulsi seeds:                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "15px", textAlign: "justify", marginLeft: "5px",  fontFamily: "Dollie Script"  }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Tulsi;
