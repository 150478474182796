import React from "react";
import "../../containers/products/Products.css";

import { Fade } from "react-reveal";
const words = "About Us".split(" ");

export default function Products(props) {
  const theme = props.theme;
  return (
    <div className="main" id="about">
      <div className="skills-header-div">
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            {words.map((word, index) => (
              <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" , fontFamily: "Times New Roman"}}>
                {word}{' '}
              </span>
            ))}
          </h1>     
        </Fade>
        <div className="contact-heading-div" style={{ marginTop: "2%" }}>
        <div className="contact-heading-img-div">
        <Fade left duration={2000} distance="20px">
        <img
                alt="Logo"
                src={require("../../assests/images/mission.png")}
              ></img>
        </Fade>
        </div>
        <div className="contact-heading-text-div">
        <Fade right duration={2000} distance="20px">
                <p className="greeting-text-p " style={{ color: theme.secondaryText }}>
                Our mission is to cultivate, manufacture, process, and serve best-in-class spices while prioritizing freshness and hygiene, ensuring they are fit for daily consumption. As spice manufacturers, traders, and wholesalers, we are committed to delivering products that exceed industry standards and delight our customers with unparalleled quality and taste.
                </p>
        </Fade>
              </div>
      </div>
      <div className="contact-heading-div" style={{ marginTop: "2%" }}>
      <Fade left duration={2000} distance="20px">
        <div className="contact-heading-img-div">
        <img
                alt="Logo"
                src={require("../../assests/images/vision.png")}
              ></img>
        </div>
    </Fade>
        <Fade right duration={2000} distance="20px">
        <div className="contact-heading-text-div">
                <p className="greeting-text-p" style={{ color: theme.secondaryText }}>
                {/* Our vision is to revolutionize the spice industry by setting new benchmarks for quality, sustainability, and customer satisfaction. We aim to create a healthier, more flavorful world, where our spices enhance every culinary experience and enrich lives. */}
                Our vision is to pioneer the spice industry with uncompromising commitment to freshness, hygiene, and excellence. We envision becoming the foremost choice for discerning consumers seeking superior quality and taste in every spice. Through relentless innovation and adherence to the highest standards. we aim to redefine the spice experience, enriching lives with flavors that inspire and delight.
                </p>
              </div>
        </Fade>
      </div>
    </div>
    </div>

  );
}
