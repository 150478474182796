import React, { Component } from "react";
import Header from "../../../components/header/Headers.js";
import TopButton from "../../../components/topButton/TopButton.js";
import { Fade } from "react-reveal";
import "./cumin.css";
import Footer from "../../../components/footer/Footer.js";

const words = "CUMIN SEEDS".split(" ");

class Cumin extends Component {
  render() {
    const theme = this.props.theme;
    const paragraphs = [
      "✔️ <strong>Appearance:</strong> Small, elongated seeds with a ridged surface and a brownish color.",
      "✔️ <strong>Flavor and Aroma:</strong> Cumin seeds have a warm, earthy, and slightly nutty flavor with a strong aroma. They contribute a distinctive taste to dishes.",
      "✔️ <strong>Culinary Uses:</strong>  Commonly used in spice blends, curry powders, and various dishes such as soups, stews, rice, and meat-based preparations. Ground cumin is also a popular spice.",
      "✔️ <strong>Digestive Aid:</strong>Cumin seeds are known for their digestive properties and are often used to alleviate indigestion, bloating, and gas. They may stimulate the production of digestive enzymes.",
      "✔️ <strong>Medicinal Uses:</strong>  In traditional medicine, cumin seeds have been used for their potential anti-inflammatory, antimicrobial, and antispasmodic properties."
    ];
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade left duration={2000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-text-div">
                <h1 className="contact-heading-text" style={{ color: theme.text,  fontFamily: "Times New Roman" }}>
                  {words.map((word, index) => (
                    <span key={index} style={{ borderBottom: index === 1 ? "4px solid #fca326" : "inherit" }}>
                      {word}{' '}
                    </span>
                  ))}
                </h1>
              </div>
            </div>
          </Fade>
          <Fade right duration={2000} distance="40px">
            <div className="contact-heading-div" style={{ marginTop: "2%" }}>
              <div className="contact-heading-img-div">
                <img alt="" src={require("../../../assests/images/d1.png")} />
              </div>
              <div className="contact-heading-text-div">
                <p className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "17px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }}>
                Cumin seeds, also known as Jeera, are the small, elongated seeds of the Cuminum cyminum plant. They are widely used as a spice in various cuisines and have been valued for their distinct flavor and potential health benefits. Here's some information about cumin seeds:
                </p>
                {paragraphs.map((paragraph, index) => (
                <p key={index} className="greeting-text-p subTitle" style={{ color: theme.secondaryText, fontSize: "15px", textAlign: "justify", marginLeft: "5px" ,  fontFamily: "Dollie Script" }} dangerouslySetInnerHTML={{ __html: paragraph }} />
                ))}
              </div>
            </div>
          </Fade>
          <div></div>
          <Fade bottom duration={2000} distance="40px">
            <div className="contact-heading-div"></div>
          </Fade>
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Cumin;
