const seo = {
  title: "KSJ Overseas",
  description:
    "Your go-to destination for premium spices. We're dedicated to delivering exceptional quality and flavor. Elevate your culinary experience with KSJ!",
};

//Home Page
const greeting = {
  title: "KSJ Overseas",
  logo_name: "kasjOverseas",
  subTitle:
    "Experience a world of flavors with KSJ – Your one-stop solution for all your spice needs. Committed to delivering exceptional quality and flavor, we strive to elevate your culinary journey. Spice up your life with KSJ today!",
};

const socialMediaLinks = [
  {
    name: "Gmail",
    link: "mailto:info@ksjoverseas.com",
    fontAwesomeIcon: "fa-google",
    backgroundColor: "#D14836",
  },
  // {
  //   name: "Instagram",
  //   link:
  //     "https://www.instagram.com/digitalease_solution?igshid=OGQ5ZDc2ODk2ZA==",
  //   fontAwesomeIcon: "fa-instagram",
  //   backgroundColor: "#E4405F",
  // },
  {
    name: "Whatsapp",
    link: "https://api.whatsapp.com/send?phone=8178210709&text=Hello",
    fontAwesomeIcon: "fa-whatsapp",
    backgroundColor: "#181717",
  },
];

const projects = {
  projects: [
    {
      title: "Ajwain / Carom Seeds / Bishop Seeds",
      subtitle: "- Known as carom seeds",
      detail: "ajwain",
      logo_path: "ajwain.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Methi Dana / Fenugreek Seeds",
      subtitle: "- Also called fenugreek seeds",
      detail: "methiDana",
      logo_path: "methi.png",
      color_code: "#CCCCCC66",
    },

    {
      title: "Kalonji / Black cumin or Black Seed",
      subtitle: "-known as black cumin or black seed",
      detail: "kalonji",
      logo_path: "kalonji.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Tulsi Seeds / Holy basil seeds",
      subtitle: "-holy basil seeds",
      detail: "tulsi",
      logo_path: "tulsi.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Dhaniya / Coriander Seeds",
      subtitle: "-Popularly known as Coriander",
      detail: "dhaniya",
      logo_path: "dhaniya.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Jeera / Cumin Seeds",
      subtitle: "-Commonly known as Cumin Seeds",
      detail: "cumin",
      logo_path: "cumin.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Herbs",
      subtitle: "-",
      detail: "herbs",
      logo_path: "herbs.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Watermelon Seeds / citrullus seeds",
      subtitle: "-citrullus seeds",
      detail: "watermelon",
      logo_path: "watermelon.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Chia Seeds - Tiny Nutritional Powerhouses",
      subtitle: "Tiny Nutritional Powerhouses",
      detail: "chia",
      logo_path: "chia.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Sesame Seeds / Til",
      subtitle: "Tiny Nutritional Powerhouses",
      detail: "sesame",
      logo_path: "sesam.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Alsi / Flaxseeds",
      subtitle: "Tiny Nutritional Powerhouses",
      detail: "alsi",
      logo_path: "alsi.png",
      color_code: "#CCCCCC66",
    },
    {
      title: "Khas Khas / Poppy Seeds",
      subtitle: "-Reecognized as Poppy Seeds",
      detail: "khaskhas",
      logo_path: "download.jpeg",
      color_code: "#CCCCCC66",
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "About Us",
};

const contactPageData = {
  contactSection: {
    title: "Contact Us",
    //   description:
    // "Ksg: Your one-stop digital partner. We excel in website development, e-commerce solutions, SEO, digital marketing, graphic design, and content writing. Elevate your brand's online presence with our comprehensive expertise.Digitalease is your go-to destination for cutting-edge development projects and tech solutions that elevate your business. It's a platform born out of a deep-rooted understanding of the intricacies of technology and a dedication to delivering top-notch solutions for your digital needs.",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    locality: "Nimach",
    country: "IN",
    region: "Madhya Pradesh",
    postalCode: "458441",
    avatar_image_path: "address_image.svg",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  seo,
  greeting,
  socialMediaLinks,
  projects,
  projectsHeader,
  contactPageData,
};
